<template>
  <b-row>
    <div class="container col-11">
        <div class="card col-12 d-flex flex-row align-items-center link" @click="redirect('carte-biometrique')">
      <img src="./stamp.svg" alt="" class="me-75" height="50" width="50">
      <div class="card-body">
        Carte d'identité biométrique
      </div>
    </div>
    <div class="card col-12 d-flex flex-row align-items-center link" @click="redirect('titre-foncier')">
      <img src="./bank.svg" alt="" class="me-75" height="50" width="50">
      <div class="card-body">
        Titre foncier
      </div>
    </div>
    <div class="card col-12 d-flex flex-row align-items-center link" @click="redirect('diplome')">
      <img src="./certificate.svg" alt="" class="me-75" height="50" width="50">
      <div class="card-body">
        Diplôme
      </div>
    </div>
    </div>
  </b-row>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { heightTransition } from '@core/mixins/ui/transition'

import {
    reactive, onMounted, watch, ref
} from '@vue/composition-api'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    required, email, confirmed, password, image,
} from '@validations'
// eslint-disable-next-line import/no-cycle
import useStructureRegister from '@/services/authentification/registerStructureService'
// eslint-disable-next-line import/no-extraneous-dependencies
import { French } from 'flatpickr/dist/l10n/fr'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/services/upload/useImageUpload'
import VueDragResize from 'vue-drag-resize'
import router from '@/router'

import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton, VBModal, BAlert, BFormFile, BCard, BForm

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import useAbonnements from '@/services/admin/abonnementService'
// eslint-disable-next-line import/no-cycle
import useTypeDocument from '@/services/admin/typeDocumentService'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        FormWizard,
        TabContent,
        BRow,
        BCol,
        BFormGroup,
        BFormFile,
        BFormInput,
        BAlert,
        BCard,
        BForm,
        BButton,
        vSelect,
        VueDragResize,
    },

    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    mixins: [heightTransition],

    data() {
        return {
            required,
            password,
            email,
            confirmed,
            image,
            tailles: [
                "16",
                "17.6",
                "19.2",
                "20.8",
                "25.6",
                "32",
                "35.2",
                "38.4",
                "41.6",
                "48",
                "52.8",
                "57.6"
            ]
        }
    },
    setup() {
        const { handleRegister, loader } = useStructureRegister()
        const {
            getClientAccess, checkAbonnement, abonnements, abonnementProcess,
        } = useAbonnements()
        const {
            handleImageSelected, fileName,
        } = useImageUpload()
        const {
            getCategorieDocuments, validate, generate, categorieDocuments, c40data,
        } = useTypeDocument()
        onMounted(async () => {
            getCategorieDocuments()
        })
        const submittedNames = []

        const items = reactive([])
        const pageNum = ref(1)
        const totalPage = ref(0)

        const canvas = ref(null)
        const canvasContainer = reactive({
            width: null,
            height: null,
        })
        const form = reactive({
            type_document: '1',
            taille: 0,
            nomDeFamille: '',
            prenom: "",
            numeroIdentificationPersonnel: "",
            nationalite: "",
            dateDeNaissance: "",
            lieuDeNaissance: "",
            autoriteDeDelivrance: "",
            dateExpiration: "",
            numeroDeCarte: "",
            sexe: "",
            profession: "",
            numeroProfessionnel: "",
            groupeSanguin: "",
        })

        const removeVal = async data => {
            console.log(form)
            form[data] = ''
        }
        const form1 = reactive({
            champ: '',
            value: '',
            pdf: []
        })
        // 
        const getDatamatrix = async () => {
            await validate({ ...form })
        }
        const config = {
            wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'j M  Y',
            altInput: true,
            dateFormat: 'Y-m-d',
            locale: French, // locale for this instance only
        }
        return {
            abonnements,
            config,
            checkAbonnement,
            form,
            submittedNames,
            items,
            getDatamatrix,
            removeVal,
            getClientAccess,
            generate,
            c40data,
            validate,
            form1,
            fileName,
            loader,
            abonnementProcess,
            getCategorieDocuments,
            categorieDocuments,
            canvasContainer,
            pageNum,
            canvas,
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    mounted() {
        this.initTrHeight()
    },
    created() {
        window.addEventListener('resize', this.initTrHeight)
    },
    destroyed() {
        window.removeEventListener('resize', this.initTrHeight)
    },
    methods: {
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmitModal()
        },
        formatter(value) {
            return value.toLowerCase()
        },
        repeateAgain() {
            this.items.push({
                id: this.nextTodoId += this.nextTodoId,
            })

            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight)
            })
            console.log(this.items)
        },
        removeItem(index, champ) {
            this.items.splice(index, 1)
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
            this.removeVal(champ)
        },
        initTrHeight() {
            this.trSetHeight(null)
        },
        redirect(type){
            router.push({ name: 'espace-tests.generation.type',params:{type} })
        },
        validationForm() {
            return new Promise((resolve, reject) => {
                this.$refs.accountRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },

        download() {
            const pdfUrl = this.fileName;
            const options = 'location=center,resizable=0,width=1024,height=630,popup'
            window.open('https://ecev-appose.mameribj.org?n=' + pdfUrl.split('CEVs/')[1], '_blank', options)
        },

        validationIsConditionForm() {
            return new Promise((resolve, reject) => {
                this.$refs.accountConditionRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        validationPersonnalForm() {
            return new Promise((resolve, reject) => {
                this.$refs.accountPersonnalRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },

        validationFilesForm() {
            return new Promise((resolve, reject) => {
                if (this.form.fichiers.length >= 2) {
                    resolve(true)
                } else {
                    reject()
                }
            })
        },
        validationEntrepriseForm() {
            return new Promise((resolve, reject) => {
                this.$refs.accountEntrepriseRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },

        handleSubmitModal() {
            this.$refs.accountFilesRules.validate().then(success => {
                if (success) {
                    this.$refs['file-input'].reset()
                    this.$nextTick(() => {
                        this.$refs['my-modal'].toggle('#toggle-btn')
                    })
                }
            })
        },

    },

}
</script>
  
<style lang="scss">
.card-body {
  position: relative;

  .pie-text {
    width: 105px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
}
.link{
    margin-bottom: 1vw !important;
    padding: 1vw 1vw;
}
.link:hover{
    cursor: pointer;
}
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
